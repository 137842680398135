<template>
    <div id="user-create">
      <v-card>
        <v-app-bar
          color="primary"
          dense
          dark
          elevate-on-scroll
          scroll-target="#scrolling-techniques-7"
        >
          <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>
  
          <v-spacer></v-spacer>
  
          <v-btn icon dark @click="closePrompt">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-container>
            <v-form ref="form" v-model="valid1" lazy-validation>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Event Name</h6>
                  <v-autocomplete
                    @change="getPlanOptions"
                    :reduce="(option) => option.value"
                    :items="EventIdOptions"
                    :rules="EventIdRules"
                    v-model="EventId"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Plan Name </h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="PlanIdOptions"
                    :rules="PlanIdRules"
                    v-model="PlanId"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
             
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    rounded
                    medium
                    color="#1db954"
                    class="font-size-h6 px-4 mr-3 white--text"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    rounded
                    medium
                    color="#a52a2a"
                    class="font-size-h6 px-4 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      
    </div>
  </template>
  
  <script>
  import common from "@/view/Common.vue";
  import companyConfig from "@/company_config.json";
  import Swal from "sweetalert2";
  export default {
    mixins: [common],
    props: {
      transferEventPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
  },
    data() {
      return {
        valid1: false,
  
        LoadingFlag: false,
        SubmitFlag: false,
        ResultFlag: false,

        EventType: "",
  
        EventIdRules: [(v) => !!v || "Event is required"],
        EventId: "",
        EventIdOptions: [],
  
        PlanIdRules: [(v) => !!v || "Plan Id is required"],
        PlanId: "",
        PlanIdOptions: [],
  
        PageInfo: {},
        PageTitle: "Transfer Event",
        PageDescription: "",
        CurrentYearId: 0,
        JciYearCode: "",
        YearName: "",
        NextYearCode: "",
        NextYearName: "",
      };
    },
    computed: {},
    watch: {
      PageInfo: function () {
        console.log("watch PageInfo");
        this.LoadingFlag = false;
        var tr = this.PageInfo;
        console.log({ tr });
        var n1 = Object.keys(tr).length;
        console.log({ n1 });
        if (n1 > 0) {
          var flag = tr.PagePermissionFlag;
          console.log("PagePermissionFlag=" + flag);
  
          if (!flag) {
            this.$router.push("/access-denied");
          }
  
          this.PageTitle = tr.PageTitle;
          this.PageDescription = tr.PageDescription;
          this.FloatingButtonOptions = tr.FloatingButtonOptions;
  
          var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
          console.log({ CurrentJciYearId });
  
          var YearName = parseInt(tr.CurrentJciYearName);
          console.log({ YearName });
          this.YearName = YearName;
  
          this.CurrentYearId = CurrentJciYearId;
  
          var server_url = companyConfig.apiURL;
          console.log("server_url = " + server_url);
          var SampleFilePath =
            server_url + "assets/sample/jcom_table_member_upload_sample.xlsx";
          console.log("SampleFilePath = " + SampleFilePath);
          this.SampleFilePath = SampleFilePath;
        }
      },
     
    },
    mounted() {},
    methods: {
      refreshPageData() {
        console.log("refreshPageData called");
        this.LoadingFlag = true;
        var url1 = "api/menu-details/show";
        var condition1 = {
          UserInterface: 1,
          Module: "jcom_members_upload",
          Action: "create",
        };
        // this.getPageDetails("PageInfo", {}, url1, condition1);
        this.pageData();
        this.LoadingFlag = false;
      },
      pageData() {
        console.log("pageData called");
        var data=this.recordData;
        console.log({data});
        this.getEventOptions();
      },
      closePrompt() {
        console.log("closePrompt called");
        this.$emit("hidetransferEventPrompt", this.ResultFlag);
      },
      getEventOptions() {
        console.log("getEventOptions called");
        var EventType=this.recordData.EventTypeId;
        var selectbox1_source = "EventId";
        var selectbox1_destination = "EventIdOptions";
        var selectbox1_url = "api/jci-program/options";
  
        var selectbox1_conditions_array = {
          UserInterface: 3,
          EventType: EventType,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
        
      },
      getPlanOptions() {
        console.log("getPlanOptions called");
        var selectbox1_source = "PlanId";
        var selectbox1_destination = "PlanIdOptions";
        var selectbox1_url = "api/jci-program/plan/options";
  
        var selectbox1_conditions_array = {
          UserInterface: 1,
          Event: this.EventId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      },
      submitForm() {
        console.log("submitForm is called");
  
        var validate1 = this.$refs.form.validate();
        console.log("validate1=" + validate1);
  
        // validate1 = false;
        var PlanId = this.PlanId == "" ? null : this.PlanId;
        var EventId = this.EventId == "" ? null : this.EventId;
        var OrdersId = this.recordData.OrdersId;
        console.log({ PlanId });
        console.log({ EventId });
        console.log({ OrdersId });
        if (validate1) {
          var upload = {
            UserInterface: 1,
            Plan: PlanId,
            Orders: OrdersId,
          };
          console.log({ upload });
  
          this.progessStart = 1;
          this.SubmitFlag = true;
          this.ResultFlag = false;
  
          var server_url = companyConfig.apiURL;
          var token = this.$session.get("token");
          token = token == (null || undefined) ? 0 : token;
          var add_url = server_url + "api/jci-program/order/transfer-event";
          console.log(
            "server_url=" +
              server_url +
              ", token=" +
              token +
              ", add_url=" +
              add_url
          );
  
          const thisIns = this;
          var output = "";
          var records = "";
          var successTxt = "";
          var errorTxt = "";
          var flag = 0;
  
          this.$http({
            url: add_url,
            method: "POST",
            data: upload,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then(function (response) {
              console.log("response=" + JSON.stringify(response));
  
              thisIns.SubmitFlag = false;
              thisIns.progessStart = 0;
  
              flag = response.data.flag;
              output = response.data.output;
              records = response.data.records;
              console.log("output=" + output + ", flag=" + flag);
              successTxt = response.data.success;
              errorTxt = response.data.error;
              console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);
  
              if (flag == 1) {
                thisIns.ResultFlag = true;
                thisIns.sweetAlert("success", output, true);
                thisIns.closePrompt();
              } else {
                thisIns.sweetAlert("error", output, false);
              }
            })
            .catch(function (error) {
              console.log("error=" + error);
              thisIns.progessStart = 0;
              thisIns.SubmitFlag = false;
            });
        } else {
          var message = "";
          if (!validate1) {
            message += "Kindly fill the required fields";
          }
          this.sweetAlert("error", message, false);
        }
      },
    },
    beforeMount() {
      this.refreshPageData();
    },
  };
  </script>
  <style lang="scss">
  </style>